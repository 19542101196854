.aTagNoDecoration a {
  text-decoration: none !important;
}

.bg-warning, .btn-warning { 
  background-color: #ff6700 !important;
}

.text-warning { 
  color: #ff6700 !important;
}

.blur {
  font-size: 40px;
  color: transparent;
  text-shadow: 0 0 8px #000;
}

.btn-warning { color: white }

button {
  white-space: nowrap;
}

body {
  font-family: 'Nexa';
}

.cardNoMargin .card-body { padding: 0 !important }

.cursor { cursor: pointer }

.dropdown-toggle::after {
  display: none;
}

.embolden:hover { cursor: pointer; font-weight: 600; }

.eye-wrap {
  cursor: pointer;
}

.fw-light {
  font-family: 'NexaLight';
}

.no-word-wrap { white-space: nowrap }

.onJumprun-h5 {
  font-size: 1.33em;
}

.onJumprunMenu {
  background-image: linear-gradient(59deg, #0e263c 35%, #061d34 35%);
}

.onJumprunMenu svg, .onJumprunMenu p, .onJumprunMenu h1, .onJumprunMenu h2, .onJumprunMenu h3, .onJumprunMenu h4, .onJumprunMenu h5, .onJumprunMenu h6 {
  color: #fff;
}

.sliderBar {
  height: 5px;
  width: 85vw;
  @media screen and (min-width: 700px) {
    width: 500px !important;
  }
}

.sliderBarButton {
  border-radius: 8px;
  height: 16px;
  left: 50%;
  margin-top: -6px;
  position: absolute;
  transform: translate(-50%, 0%);
  width: 16px;
}

td:hover {
  text-decoration: underline;
}

tbody {
  font-size: 13px;
  font-weight: 600;
}

.underline { text-decoration: underline }

.uppercase { text-transform: uppercase; }

.darkMode .onJumprunMenu {
  background-image: linear-gradient(59deg, 	#1e1e1e 35%, #141414 35%);
}
.darkMode .btn-warning { border: unset; color: black }
.darkMode small { color: white !important }
.darkMode .bg-light {
  color: #061d34;
}
.darkMode .nav-tabs .nav-link { color: gray }
.darkMode .nav-tabs .nav-link.active { color: white }
.border-right { border-right: 1px solid #6a7176 }

.eye { display: none }
.eye-wrap:hover { background-color: #1b2f43; opacity: 1 !important; }
.darkMode .eye-wrap:hover { background-color: #1e1e1e !important; opacity: 1 !important; }
.eye-wrap:hover .eye { display: block }

.fadeIn {
  opacity: 0;
  animation: fadeIn .97s ease-in;
  animation-delay: .82;
  animation-fill-mode: forwards;
}

.overflow-x-none { 
  overflow-x: hidden !important;
}

.fadeInFast {
  animation: fadeIn .5s linear;
  animation-delay: 0.420s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.fadeInSlow {
  animation: fadeIn 1.22s linear;
  animation-fill-mode: forwards;
  opacity: 0;
}

.text-pink { 
  color: pink;
}
.form-control.is-invalid { 
  background-color: #FFCCCB;
  box-shadow: 0 0 0 0.25rem transparent !important;
  color: black;
}
.darkMode .form-control.is-invalid { 
  background-color: red !important;
}
.form-control.is-valid { 
  background-color: #E4FAE4;
  box-shadow: 0 0 0 0.25rem transparent !important;
  color: black
}
.darkMode .form-control.is-valid { 
  background-color: black !important;
}

.greenOnHover {
  fill: lime
}

.text-purple { 
  color: purple;
}

.onjumprun-footer { 
  background-color: #060C0C;
  font-weight: 600;
  overflow: unset;
}

.fixed-center {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
}

.shimmer {
  animation: shimmer 2.2s infinite;
  background-repeat: no-repeat;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
}

.shimmerHover {
  animation: shimmer 4.6s infinite;
  animation-delay: 6s;
  background-repeat: no-repeat;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
}

.showOnHover { display: none;}

.delete-row-hover:hover .hideOnHover { display: none }
.delete-row-hover:hover .showOnHover { display: block }

td { cursor: pointer; }

.green-row td {
  background-color: #d2f8d2;
}

.btn-outline-warning, .border-warning, .btn-outline-warning svg {
  border-color: rgb(255, 103, 0) !important;
  color: white;
}

.btn-outline-warning:hover, .btn-outline-warning:hover svg {
  background-color: rgb(255, 103, 0);
  inset: 1px 1px rgb(255, 103, 0);
  color: white;
}

.red-row td {
  background-color: #FFCCCB;
  font-weight: 600;
}

.gray-row td {
  background-color: #cbcbcb;
  font-weight: 600;
}

.darkMode .gray-row td {
  background-color: #141414;
  font-weight: 600;
}

.darkMode .red-row td, .darkMode .green-row td {
  color: black;
}

.month {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
}

.pulse {
  animation: pulse 4.2s infinite;
  animation-delay: 4s;
}

.pulseFast {
  animation: pulseFast 1.69s infinite;
}

.swellMe {
  animation: swell 6s infinite;
  color: orangered
}

.pulseFont { 
  animation: pulseFont .92s infinite;
}
@keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes pulseFont {
  0% { font-size: .83em; fill: rgb(255, 210, 125); }
  50% { font-size: 1.02em; fill: rgb(255, 103, 0); }
  100% { font-size: .83em; fill: rgb(255, 210, 125);; }
}

@keyframes pulse {
  30% { opacity: 1 }
  50% { opacity: 0.59 }
  75% { opacity: 1 }
}

@keyframes pulseFast {
  10% { opacity: 1 }
  50% { opacity: 0.59 }
  90% { opacity: 1 }
}

@keyframes shimmer {
  100% { -webkit-mask-position: left }
}

@keyframes swell {
  10% { color: orangered }
  40% { color: red }
  60% { color: darkred }
  80% { color: orangered }
}

.darkMode .card { border-color: gray }
∂
.orangeOnHover {
  fill: red
}

.table-responsive {
  border-radius: 0.375rem;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.onJumprun-row-left {
  border-bottom-left-radius: 0.375rem;
}

.onJumprun-row-right {
  border-bottom-right-radius: 0.375rem;
}

.absolute-bottom {
  position: absolute;
  bottom: 0;
}

.zeroStep {
  background-image: linear-gradient(90deg, #0276ab 6%, lightgray 7%);
}

.calendarAndTimeslotStep {
  background-image: linear-gradient(90deg, #0276ab 28%, lightgray 29%);
}

.addJumperStep {
  background-image: linear-gradient(90deg, #0276ab 55%, lightgray 55%);
}

.lastStep {
  background-image: linear-gradient(90deg, #0276ab 100%, lightgray 100%);
}

.not-in-selected-range {
  color: black !important;
}

.arrow-disabled {
  color: lightgray;
  cursor: pointer;
}

.whiteOnHover:hover {
  color: white !important;
  opacity: 0.85 !important;
  text-decoration: underline;
}

.vh-75 {
  height: 75vh
}
.vh-95 {
  height: 97vh;
}